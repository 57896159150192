/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountGroupDto { 
    id?: string | null;
    name?: string | null;
    aboutUsUrl?: string | null;
    availableCurrencies?: string | null;
    ssuBaseCurrency?: string | null;
    availableLocales?: string | null;
    country?: string | null;
    defaultLocale?: string | null;
    homepageUrl?: string | null;
    ssu?: string | null;
    label?: string | null;
    ssuName?: string | null;
    phone?: string | null;
    postCode?: string | null;
    shortcut?: string | null;
    availableCountries?: string | null;
    facebookLink?: string | null;
    instagramLink?: string | null;
    linkedinLink?: string | null;
    twitterLink?: string | null;
    xingkLink?: string | null;
    youtubeLink?: string | null;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    addressLineThree?: string | null;
    addressLineFour?: string | null;
    isGuest?: boolean;
}

