import {Inject, Injectable, OnDestroy} from '@angular/core';
import { parseUrlParameter, UserService } from '@neo-reward-engine-web/auth';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CommunityUserService,
  UserInfo,
  AuthService as EcomAuthService
} from '@neo-reward-engine-web/ecom-api';
import { EnvironmentService } from '../environment/environment.service';
import {NGXLogger} from "ngx-logger";
import {APP_USER_SERVICE_CONFIG} from "./user-service.config";

/**
 * Sets Language of the User after Loging in for transloco
 *
 * TODO: Circular dependency between lib and app going to be removed later
 */
@Injectable({
  providedIn: 'root',
})
export class MultilanguageService implements OnDestroy {
  private eShopLang$ = new BehaviorSubject<string | null>(null);

  subscriptions: Subscription = new Subscription();

  private readonly _defaultLangMap: Map<string, string>;

  /**
   *
   * @param config injected to get transformation to default lang context
   * @param translocoService injected to save the language
   * @param userService injected to check user state
   * @param communityUserService injected to get user language fallback hirachy
   * @param environmentService injected to get sandbox for request
   * @param ecomAuthService injected to get user info
   * @param logger injected for logging
   */
  constructor(
    @Inject(APP_USER_SERVICE_CONFIG) private readonly config: string[],
    private readonly translocoService: TranslocoService,
    private readonly userService: UserService,
    private readonly communityUserService: CommunityUserService,
    private readonly environmentService: EnvironmentService,
    private readonly ecomAuthService: EcomAuthService,
    private readonly logger: NGXLogger
  ) {
    this._defaultLangMap = this.config.reduce((map, lang) =>
      map.set(lang.split('-')[0], lang)
    , new Map<string, string>());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  upsertLanguage() {
    if (parseUrlParameter(window.location.href).has('lang')) {
      const eShopLang = parseUrlParameter(window.location.href).get('lang');
      this.eShopLang$.next(eShopLang);
    }
  }

  setLanguage() {
    const loggedIn =
      this.userService.userState$.value?.userType === 'Community' ||
      this.userService.userState$.value?.userType === 'Admin';

    if (loggedIn) {
      this.setLanguageFromUser();
    } else {
      if (
        this.eShopLang$.value &&
        this.translocoService.isLang(this.eShopLang$.value)
      ) {
        this.logger.debug('MultilanguageService - eshopLang&$ is set: ', this.eShopLang$.value);
        this.translocoService.setActiveLang(this.eShopLang$.value);
      } else if (
        navigator.language &&
        this.translocoService.isLang(this.transformSimpleLanguageCode(navigator.language))
      ) {
        this.logger.debug('MultilanguageService - eshopLang&$ is not set, using navigator lang: ', navigator.language);
        this.translocoService.setActiveLang(this.transformSimpleLanguageCode(navigator.language));
      } else {
        this.logger.debug(
          'MultilanguageService - eshopLang&$ is not set we are using defaultLang from Transloco: ',
          this.translocoService.getDefaultLang()
        );
        this.translocoService.setActiveLang(
          this.translocoService.getDefaultLang()
        );
      }
    }
  }
  /**
   * uses fallback hirachy
   * userlang (backend checks for contact -> account -> accountgroup) -> browser language -> default (en)
   */
  setLanguageFromUser() {
    const userInfo$: Observable<UserInfo> =
      this.ecomAuthService.getUserInfo(this.environmentService.sandBox);
    this.subscriptions.add(userInfo$.pipe(take(1)).subscribe({
      next: (user) => {
        const userLang = this.languageCodeFormatter(user.language);
        if (userLang && this.translocoService.isLang(userLang)) {
          this.translocoService.setActiveLang(userLang);
        } else if (
          navigator.language &&
          this.translocoService.isLang(navigator.language)
        ) {
          this.translocoService.setActiveLang(navigator.language);
        } else {
          this.translocoService.setActiveLang(
            this.translocoService.getDefaultLang()
          );
        }
      },
      error: (error) => console.log(error),
    }));
  }
  /** needed because we have de_DE in salesforce and de-DE here */
  languageCodeFormatter(langCode: string | undefined | null) {
    if (!langCode) {
      return null;
    }
    return langCode.replace('_', '-');
  }

  transformSimpleLanguageCode(langCode: string):string {
    this.logger.debug('MultilanguageService - transformSimpleLanguageCode: ', langCode, this._defaultLangMap);
    if (langCode.includes('-')) {
      return langCode;
    }
    return this._defaultLangMap.get(langCode) ?? 'en-US';
  }
}
