import { ToastService } from './../toast/toast.service';
import { EnvironmentService } from './../environment/environment.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  CommunityUserService,
  RewardProductDto,
  RewardProgramDto,
  RewardRuleDto,
} from '@neo-reward-engine-web/ecom-api';
import { Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { StandardErrorHandlerService } from '../error-handler/standard-error-handler.service';
import { UserService } from '@neo-reward-engine-web/auth';

/** service that only handles the configuration of the reward program */
@Injectable({
  providedIn: 'root',
})
export class ProgramSettingsService implements OnDestroy {
  /** private programsettings to be used in retrieval success */
  private _programSettings$: BehaviorSubject<RewardProgramDto | undefined> =
    new BehaviorSubject<RewardProgramDto | undefined>(undefined);
  private _programProducts$: BehaviorSubject<RewardProductDto[] | undefined> =
    new BehaviorSubject<RewardProductDto[] | undefined>(undefined);

  subscriptions: Subscription = new Subscription();

  /**
   *
   * @param communityUserService injected to retrieve settings
   * @param logger injected to log info on success
   * @param standardErrorHandlerService injected to handle http error
   * @param environmentService injected to retrieve sandbox configuration
   */
  constructor(
    private communityUserService: CommunityUserService,
    private readonly logger: NGXLogger,
    private readonly standardErrorHandlerService: StandardErrorHandlerService,
    private environmentService: EnvironmentService,
    private userSerivce: UserService,
    private toastService: ToastService
  ) {
    //
  }

  // TODO - a service does never call ngOnDestroy since a singleton will have no lifecycle - clenaup later
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /** exposes the programSettings */
  get programSettings$(): BehaviorSubject<RewardProgramDto | undefined> {
    return this._programSettings$;
  }

  /** exposes the programRules */
  get programProducts$(): BehaviorSubject<RewardProductDto[] | undefined> {
    return this._programProducts$;
  }

  /**
   * retrieves the program settings via communityUserService
   */
   private retrieveProgramSettings(): void {
    this.subscriptions.add(this.communityUserService
      .getUserProgramSettings(this.environmentService.sandBox)
      .subscribe({
        next: (value) => {
          this._programSettings$.next(value);
          if (value && !value.enabled) {
            this.toastService.showInfoMessage('register.registration.noProgram',
              {
                toastConfig: { autoClose: false, dismissible: true }, placeholder: undefined,
              });
          }
          this.logger.info(
            'ProgramSettingsService.retrieveProgramSettings successfull'
          );
        },
        error: (error) => {
          this.standardErrorHandlerService.handleError(
            error,
            'ProgramSettingsService.retrieveProgramSettings'
          );
        },
      }));
  }

  /**
   * retrieves the program catalogue via communityUserService
   */
   private retrieveProgramCatalogue(): void {
    this.subscriptions.add(this.communityUserService
      .getProgramRewardProducts(this.environmentService.sandBox)
      .subscribe({
        next: (value) => {
          this._programProducts$.next(value);
          this.logger.info(
            'ProgramSettingsService.retrieveProgramCatalogue successfull'
          );
        },
        error: (error) => {
          this.standardErrorHandlerService.handleError(
            error,
            'ProgramSettingsService.retrieveProgramCatalogue'
          );
        },
      }));
  }

  /** combiner method that calls all retrieval methods if user is logged in */
  public retrieveAllProgramSpecificSettings() {
    if(this.userSerivce.userState$.value?.userType != 'Community'){
      return;
    }
    this.retrieveProgramSettings();
    this.retrieveProgramCatalogue();
  }
}
