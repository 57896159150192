/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ContactAddressDto
 */
export interface ContactAddressDto { 
    id?: string | null;
    addressFirstLine?: string | null;
    addressSecondLine?: string | null;
    addressThirdLine?: string | null;
    addressFourthLine?: string | null;
    city?: string | null;
    companyName?: string | null;
    contactAddressId?: string | null;
    countryISOCode?: string | null;
    country?: string | null;
    firstName?: string | null;
    homePhone?: string | null;
    lastName?: string | null;
    postalCode?: string | null;
    stateISOCode?: string | null;
    state?: string | null;
    region?: string | null;
    salutation?: string | null;
    sapAddressNumber?: string | null;
}

