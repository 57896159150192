/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *  CC Category    SObject Name: ccrz__E_Category__c  Custom Object: True
 */
export interface Category { 
    /**
     * Record ID    Name: Id  SF Type: id  Nillable: False
     */
    id?: string | null;
    /**
     * Category Name    Name: Name  SF Type: string  Nillable: True
     */
    name?: string | null;
    /**
     * Category ID    Name: ccrz__CategoryID__c  SF Type: string  Custom field  Nillable: False
     */
    ccrz__CategoryID__c?: string | null;
    /**
     * Parent Category    Name: ccrz__ParentCategory__c  SF Type: reference  Custom field  Nillable: True
     */
    ccrz__ParentCategory__c?: string | null;
}

