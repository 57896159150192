import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  isRedirectToCasRequired,
  parseUrlParameter,
  redirectToCas,
  checkIfTokenExpired, mapBackendUserState, createIdmRedirectUrl4Admin,
} from './auth-utils';
import {map, Observable} from 'rxjs';
import {concatMap, delay, tap} from 'rxjs/operators';
import {AuthResponse} from './auth-repsonse';
import {AuthConfigService} from './auth-config.service';
import {UserService} from './user.service';
import {UserState} from './user.state';
import {NGXLogger} from 'ngx-logger';
import {loggerFn} from '../common-helper/logger';
import {Location} from '@angular/common';
import {AccessToken} from './access.token';
import {AuthService as EcomAuthService} from '@neo-reward-engine-web/ecom-api';
import {DateTime} from "luxon";

export class AuthAdminService {
  constructor(
    private readonly configuration: AuthConfigService,
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    private location: Location,
    private readonly ecomAuthService: EcomAuthService
  ) {
  }

  public initAuth(): Promise<void> {
    return new Promise<void>((resolve) => {
      let isAuthenticatedByExternalSystem = false;
      // Check if we already went to CAS and got an answer
      if (parseUrlParameter(window.location.href).has('code')) {
        // We received the Authorization Code from CAS
        // Retrieve Access token
        const authCode = parseUrlParameter(window.location.href).get('code');

        this.retrieveAccessCode(authCode!)
          .pipe(
            delay(1000), // Animation
            tap(() => {
              this.location.replaceState(window.location.pathname);
            }),
            loggerFn(
              this.logger,
              'AuthService.initAuth',
              'retrieving token from CAS: '
            ),
            tap(res => res.id_token ? isAuthenticatedByExternalSystem = true : isAuthenticatedByExternalSystem = false),
            concatMap((res) => this.authenticateUser(res.id_token)),
            loggerFn(
              this.logger,
              'AuthService.initAuth',
              'retrieving access token from backend: '
            ),
            tap((res) => this.userService.upsertToken(res)),
            concatMap(() => this.retrieveUserInfo()),
            loggerFn(
              this.logger,
              'AuthService.initAuth',
              'retrieving user info from backend: '
            )
          )
          .subscribe({
            next: (res) => {
              this.userService.upsertUserState(mapBackendUserState(res));
              this.userService.has401 = false;
              resolve();
            },
            error: (error) => {
              this.logger.error('AuthService.initAuth() has error: ', error);
              if (error.status === 404) {
                this.userService.has401 = true;
              }
              this.userService.upsertUserState({
                isUnauthorized: true,
                isAuthenticated: isAuthenticatedByExternalSystem,
              })
              resolve();
            },
          });
      } else if (
        this.checkIfTokenAndUserInfoIsPresent() || !isRedirectToCasRequired(
          this.configuration.config.publicUrls,
          new URL(window.location.href)
        )
      ) {
        resolve();
        //resolve and do nothing (stay on public route)
      }

      // redirect to CAS - BaseUrl + spec URL
      else {
        if (this.checkIfTokenAndUserInfoIsPresent()) {
          resolve()
        } else {
          redirectToCas(
            createIdmRedirectUrl4Admin(
              this.configuration.config.casBaseUrl,
              this.configuration.config.casClientId,
              window.location.href,
              this.configuration.config.locale!,
              this.configuration.config.idmAdminRedirectUri
            )
          );
          // TODO Do we really reach this code?
          resolve();
        }
      }
    });
  }

  private checkIfTokenAndUserInfoIsPresent(): boolean {
    const accessTokenSerialized = sessionStorage.getItem(this.configuration.config.rewardTokenStorageKey);
    let accessToken: AccessToken;
    let userState: UserState;
    // General check for access token
    if (accessTokenSerialized) {
      accessToken = JSON.parse(accessTokenSerialized);
    } else {
      // No access token found, return false
      return false;
    }
    // Check if token has expired and userInfo is present
    if (checkIfTokenExpired(accessToken.expiresAt, DateTime.now())) {
      return false;
    }

    const userStateSerialized = sessionStorage.getItem(this.configuration.config.rewardUserStorageKey);
    if (userStateSerialized) {
      userState = JSON.parse(userStateSerialized);
    } else {
      return false;
    }

    // Hydrate data
    this.userService.upsertToken(accessToken, false);
    this.userService.upsertUserState(userState, false);
    return true;
  }

  private retrieveAccessCode(code: string): Observable<AuthResponse> {
    let body = new HttpParams();
    body = body.set('client_id', this.configuration.config.casClientId);
    // This was the old redirect_uri configuration we have to see that this is not breaking anything
    // body = body.set('redirect_uri', `${this.configuration.config.redirectUri}overview`);
    body = body.set(
      'redirect_uri',
      `${this.configuration.config.redirectUri}${this.location.path().split('?')[0]}`
    );
    body = body.set('grant_type', 'authorization_code');
    body = body.set('code', code);
    body = body.set('client_secret', this.configuration.config.secret);

    let headers = new HttpHeaders();
    headers = headers.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers = headers.append(
      'Authorization',
      `Basic ${btoa(
        this.configuration.config.casClientId +
        ':' +
        this.configuration.config.secret
      )}`
    );

    return this.http.post<AuthResponse>(
      `${this.configuration.config.casBaseUrl}${this.configuration.config.idmAccessTokenUrl}`,
      body,
      {
        headers: headers,
      }
    );
  }

  private retrieveUserInfo(): Observable<UserState> {
    return this.ecomAuthService
      .getUserInfo(this.configuration.config.sandbox)
      .pipe(
        map(
          (userInfo) =>
            ({
              sapAccountNumber: userInfo.sapAccountNumber?.value,
              accountName: userInfo.accountName,
              contactName: userInfo.contactName,
              userType: userInfo.userType,
              ssu: userInfo.ssu,
              isMainContact: userInfo.isMainContact,
              isSuperAdmin: userInfo.isSuperAdmin,
              status: userInfo.status,
              canRedeem: userInfo.canRedeem,
              relatedSsu: userInfo.relatedSsu
            } as UserState)
        )
      );
  }

  private authenticateUser(jwtToken: string): Observable<AccessToken> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${jwtToken}`);
    this.logger.debug(`Bearer ${jwtToken}`);
    return this.http.get<AccessToken>(
      `${this.configuration.config.backendUrl}/api/v1/sandbox/${this.configuration.config.sandbox}/auth/token`,
      {
        headers,
      }
    );
  }
}
