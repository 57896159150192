/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AdminAppDefaults } from '../model/models';
import { AdminTransactionModel } from '../model/models';
import { DeleteOperationResult } from '../model/models';
import { ReplaceOneOperationResult } from '../model/models';
import { RewardAccountDto } from '../model/models';
import { RewardAccountStatus } from '../model/models';
import { RewardEngineError } from '../model/models';
import { RewardOrderDto } from '../model/models';
import { RewardOrderViewDtoPaginatedList } from '../model/models';
import { RewardProduct } from '../model/models';
import { RewardProductDto } from '../model/models';
import { RewardProgram } from '../model/models';
import { RewardRule } from '../model/models';
import { RewardRuleDto } from '../model/models';
import { RewardTier } from '../model/models';
import { RewardTransactionDto } from '../model/models';
import { RewardUser } from '../model/models';
import { RuleType } from '../model/models';
import { SimplePointHistoryViewDto } from '../model/models';
import { SortingDirection } from '../model/models';
import { SsuEmailSettings } from '../model/models';
import { SsuInfoDto } from '../model/models';
import { UserInfo } from '../model/models';
import { UserPermission } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete a product
     * @param id 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRewardProduct(id: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeleteOperationResult>;
    public deleteRewardProduct(id: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeleteOperationResult>>;
    public deleteRewardProduct(id: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeleteOperationResult>>;
    public deleteRewardProduct(id: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteRewardProduct.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<DeleteOperationResult>(`${this.configuration.basePath}/api/v1/product/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a product
     * @param id 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRewardProductSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeleteOperationResult>;
    public deleteRewardProductSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeleteOperationResult>>;
    public deleteRewardProductSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeleteOperationResult>>;
    public deleteRewardProductSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteRewardProductSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling deleteRewardProductSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<DeleteOperationResult>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/product/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a rule
     * @param id 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRewardRule(id: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteRewardRule(id: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteRewardRule(id: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteRewardRule(id: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteRewardRule.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/rule/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a rule
     * @param id 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRewardRuleSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteRewardRuleSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteRewardRuleSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteRewardRuleSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteRewardRuleSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling deleteRewardRuleSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/rule/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete EShopOrderInProgress transaction
     * @param id 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTransaction(id: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteTransaction(id: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteTransaction(id: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteTransaction(id: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTransaction.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/transaction/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete EShopOrderInProgress transaction
     * @param id 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTransactionSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteTransactionSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteTransactionSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteTransactionSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTransactionSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling deleteTransactionSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/transaction/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets orders for one or all accounts
     * @param ssu 
     * @param accountId Account to get orders for. Get all orders for the ssu if null.
     * @param orderIdQuery 
     * @param limit Return this many results at once
     * @param offset Offset result list by this many items
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountOrders(ssu: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardOrderViewDtoPaginatedList>;
    public getAccountOrders(ssu: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardOrderViewDtoPaginatedList>>;
    public getAccountOrders(ssu: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardOrderViewDtoPaginatedList>>;
    public getAccountOrders(ssu: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getAccountOrders.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountId !== undefined && accountId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountId, 'accountId');
        }
        if (orderIdQuery !== undefined && orderIdQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderIdQuery, 'orderIdQuery');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardOrderViewDtoPaginatedList>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/orders`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets orders for one or all accounts
     * @param ssu 
     * @param sandbox 
     * @param accountId Account to get orders for. Get all orders for the ssu if null.
     * @param orderIdQuery 
     * @param limit Return this many results at once
     * @param offset Offset result list by this many items
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountOrdersSandbox(ssu: string, sandbox: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardOrderViewDtoPaginatedList>;
    public getAccountOrdersSandbox(ssu: string, sandbox: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardOrderViewDtoPaginatedList>>;
    public getAccountOrdersSandbox(ssu: string, sandbox: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardOrderViewDtoPaginatedList>>;
    public getAccountOrdersSandbox(ssu: string, sandbox: string, accountId?: string, orderIdQuery?: string, limit?: number, offset?: number, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getAccountOrdersSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getAccountOrdersSandbox.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountId !== undefined && accountId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountId, 'accountId');
        }
        if (orderIdQuery !== undefined && orderIdQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderIdQuery, 'orderIdQuery');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardOrderViewDtoPaginatedList>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/orders`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get account transactions
     * @param sapAccountNumber 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountTransactions(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardTransactionDto>>;
    public getAccountTransactions(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardTransactionDto>>>;
    public getAccountTransactions(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardTransactionDto>>>;
    public getAccountTransactions(sapAccountNumber: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling getAccountTransactions.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardTransactionDto>>(`${this.configuration.basePath}/api/v1/account/${encodeURIComponent(String(sapAccountNumber))}/transactions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get account transactions
     * @param sapAccountNumber 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountTransactionsSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardTransactionDto>>;
    public getAccountTransactionsSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardTransactionDto>>>;
    public getAccountTransactionsSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardTransactionDto>>>;
    public getAccountTransactionsSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling getAccountTransactionsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getAccountTransactionsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardTransactionDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/account/${encodeURIComponent(String(sapAccountNumber))}/transactions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users information of the given account id
     * @param sapAccountNumber 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountUserInfos(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<UserInfo>>;
    public getAccountUserInfos(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<UserInfo>>>;
    public getAccountUserInfos(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<UserInfo>>>;
    public getAccountUserInfos(sapAccountNumber: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling getAccountUserInfos.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<UserInfo>>(`${this.configuration.basePath}/api/v1/account/${encodeURIComponent(String(sapAccountNumber))}/users`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users information of the given account id
     * @param sapAccountNumber 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountUserInfosSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<UserInfo>>;
    public getAccountUserInfosSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<UserInfo>>>;
    public getAccountUserInfosSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<UserInfo>>>;
    public getAccountUserInfosSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling getAccountUserInfosSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getAccountUserInfosSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<UserInfo>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/account/${encodeURIComponent(String(sapAccountNumber))}/users`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get default values for admin app
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminAppDefaults(hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AdminAppDefaults>;
    public getAdminAppDefaults(hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AdminAppDefaults>>;
    public getAdminAppDefaults(hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AdminAppDefaults>>;
    public getAdminAppDefaults(hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AdminAppDefaults>(`${this.configuration.basePath}/api/v1/adminDefaults`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get default values for admin app
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminAppDefaultsSandbox(sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AdminAppDefaults>;
    public getAdminAppDefaultsSandbox(sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AdminAppDefaults>>;
    public getAdminAppDefaultsSandbox(sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AdminAppDefaults>>;
    public getAdminAppDefaultsSandbox(sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getAdminAppDefaultsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AdminAppDefaults>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/adminDefaults`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return a reward order given its id
     * @param id 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrder(id: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardOrderDto>;
    public getOrder(id: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardOrderDto>>;
    public getOrder(id: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardOrderDto>>;
    public getOrder(id: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrder.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardOrderDto>(`${this.configuration.basePath}/api/v1/order/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return a reward order given its id
     * @param id 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardOrderDto>;
    public getOrderSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardOrderDto>>;
    public getOrderSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardOrderDto>>;
    public getOrderSandbox(id: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrderSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getOrderSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardOrderDto>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/order/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return RewardAdminUser
     * @param adminId 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardAdminUser(adminId: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardUser>;
    public getRewardAdminUser(adminId: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardUser>>;
    public getRewardAdminUser(adminId: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardUser>>;
    public getRewardAdminUser(adminId: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling getRewardAdminUser.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardUser>(`${this.configuration.basePath}/api/v1/admin/user/${encodeURIComponent(String(adminId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return RewardAdminUser
     * @param adminId 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardAdminUserSandbox(adminId: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardUser>;
    public getRewardAdminUserSandbox(adminId: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardUser>>;
    public getRewardAdminUserSandbox(adminId: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardUser>>;
    public getRewardAdminUserSandbox(adminId: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling getRewardAdminUserSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getRewardAdminUserSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardUser>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/admin/user/${encodeURIComponent(String(adminId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List products
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardProducts(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardProductDto>>;
    public getRewardProducts(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardProductDto>>>;
    public getRewardProducts(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardProductDto>>>;
    public getRewardProducts(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getRewardProducts.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardProductDto>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/products`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List products
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardProductsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardProductDto>>;
    public getRewardProductsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardProductDto>>>;
    public getRewardProductsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardProductDto>>>;
    public getRewardProductsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getRewardProductsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getRewardProductsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardProductDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/products`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get rules
     * @param ssu 
     * @param type 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardRules(ssu: string, type: RuleType, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardRuleDto>>;
    public getRewardRules(ssu: string, type: RuleType, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardRuleDto>>>;
    public getRewardRules(ssu: string, type: RuleType, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardRuleDto>>>;
    public getRewardRules(ssu: string, type: RuleType, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getRewardRules.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getRewardRules.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardRuleDto>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/rules/type/${encodeURIComponent(String(type))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get rules
     * @param ssu 
     * @param type 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardRulesSandbox(ssu: string, type: RuleType, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardRuleDto>>;
    public getRewardRulesSandbox(ssu: string, type: RuleType, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardRuleDto>>>;
    public getRewardRulesSandbox(ssu: string, type: RuleType, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardRuleDto>>>;
    public getRewardRulesSandbox(ssu: string, type: RuleType, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getRewardRulesSandbox.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getRewardRulesSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getRewardRulesSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardRuleDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/rules/type/${encodeURIComponent(String(type))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return RewardUser
     * @param contactId 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardUser(contactId: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardUser>;
    public getRewardUser(contactId: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardUser>>;
    public getRewardUser(contactId: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardUser>>;
    public getRewardUser(contactId: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling getRewardUser.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardUser>(`${this.configuration.basePath}/api/v1/user/${encodeURIComponent(String(contactId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return RewardUser
     * @param contactId 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRewardUserSandbox(contactId: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardUser>;
    public getRewardUserSandbox(contactId: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardUser>>;
    public getRewardUserSandbox(contactId: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardUser>>;
    public getRewardUserSandbox(contactId: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling getRewardUserSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getRewardUserSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardUser>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/user/${encodeURIComponent(String(contactId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information about an SSUs email settings
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSsuEmailSettings(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SsuEmailSettings>;
    public getSsuEmailSettings(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SsuEmailSettings>>;
    public getSsuEmailSettings(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SsuEmailSettings>>;
    public getSsuEmailSettings(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getSsuEmailSettings.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SsuEmailSettings>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/ssuEmailSettings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information about an SSUs email settings
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSsuEmailSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SsuEmailSettings>;
    public getSsuEmailSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SsuEmailSettings>>;
    public getSsuEmailSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SsuEmailSettings>>;
    public getSsuEmailSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getSsuEmailSettingsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getSsuEmailSettingsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SsuEmailSettings>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/ssuEmailSettings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information about an SSU
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSsuInfo(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SsuInfoDto>;
    public getSsuInfo(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SsuInfoDto>>;
    public getSsuInfo(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SsuInfoDto>>;
    public getSsuInfo(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getSsuInfo.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SsuInfoDto>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/info`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information about an SSU
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSsuInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SsuInfoDto>;
    public getSsuInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SsuInfoDto>>;
    public getSsuInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SsuInfoDto>>;
    public getSsuInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getSsuInfoSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getSsuInfoSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SsuInfoDto>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/info`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information about total balance over time for all ssu
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTotalBalanceHistory(hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SimplePointHistoryViewDto>>;
    public getTotalBalanceHistory(hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SimplePointHistoryViewDto>>>;
    public getTotalBalanceHistory(hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SimplePointHistoryViewDto>>>;
    public getTotalBalanceHistory(hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<SimplePointHistoryViewDto>>(`${this.configuration.basePath}/api/v1/balanceHistory`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets information about total balance over time for all ssu
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTotalBalanceHistorySandbox(sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SimplePointHistoryViewDto>>;
    public getTotalBalanceHistorySandbox(sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SimplePointHistoryViewDto>>>;
    public getTotalBalanceHistorySandbox(sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SimplePointHistoryViewDto>>>;
    public getTotalBalanceHistorySandbox(sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getTotalBalanceHistorySandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<SimplePointHistoryViewDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/balanceHistory`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnprocessedOrdersAmount(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public getUnprocessedOrdersAmount(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public getUnprocessedOrdersAmount(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public getUnprocessedOrdersAmount(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getUnprocessedOrdersAmount.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/orders/unprocessed`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnprocessedOrdersAmountSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public getUnprocessedOrdersAmountSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public getUnprocessedOrdersAmountSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public getUnprocessedOrdersAmountSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling getUnprocessedOrdersAmountSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling getUnprocessedOrdersAmountSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/orders/unprocessed`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get contacts requesting to join
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinRequests(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardUser>>;
    public joinRequests(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardUser>>>;
    public joinRequests(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardUser>>>;
    public joinRequests(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling joinRequests.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardUser>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/joinRequests`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get contacts requesting to join
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinRequestsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardUser>>;
    public joinRequestsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardUser>>>;
    public joinRequestsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardUser>>>;
    public joinRequestsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling joinRequestsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling joinRequestsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardUser>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/joinRequests`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Onboarding of new RewardAccounts, also including RewardUsers and RewardPermissions
     * @param sapAccountNumber 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onboardRewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public onboardRewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public onboardRewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public onboardRewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling onboardRewardAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sapAccountNumber !== undefined && sapAccountNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sapAccountNumber, 'sapAccountNumber');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/OnboardRewardAccount`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Onboarding of new RewardAccounts, also including RewardUsers and RewardPermissions
     * @param sapAccountNumber 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onboardRewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public onboardRewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public onboardRewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public onboardRewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling onboardRewardAccountSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling onboardRewardAccountSandbox.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sapAccountNumber !== undefined && sapAccountNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sapAccountNumber, 'sapAccountNumber');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/OnboardRewardAccount`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get account
     * @param sapAccountNumber 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardAccountDto>;
    public rewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardAccountDto>>;
    public rewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardAccountDto>>;
    public rewardAccount(sapAccountNumber: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling rewardAccount.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardAccountDto>(`${this.configuration.basePath}/api/v1/account/sapAccountNumber/${encodeURIComponent(String(sapAccountNumber))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get account
     * @param sapAccountNumber 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardAccountDto>;
    public rewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardAccountDto>>;
    public rewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardAccountDto>>;
    public rewardAccountSandbox(sapAccountNumber: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling rewardAccountSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardAccountSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardAccountDto>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/account/sapAccountNumber/${encodeURIComponent(String(sapAccountNumber))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set account status
     * @param sapAccountNumber 
     * @param status 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountStatus(sapAccountNumber: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public rewardAccountStatus(sapAccountNumber: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public rewardAccountStatus(sapAccountNumber: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public rewardAccountStatus(sapAccountNumber: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling rewardAccountStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling rewardAccountStatus.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/account/${encodeURIComponent(String(sapAccountNumber))}/accountStatus/${encodeURIComponent(String(status))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set account status
     * @param sapAccountNumber 
     * @param status 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountStatusSandbox(sapAccountNumber: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public rewardAccountStatusSandbox(sapAccountNumber: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public rewardAccountStatusSandbox(sapAccountNumber: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public rewardAccountStatusSandbox(sapAccountNumber: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling rewardAccountStatusSandbox.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling rewardAccountStatusSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardAccountStatusSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/account/${encodeURIComponent(String(sapAccountNumber))}/accountStatus/${encodeURIComponent(String(status))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get accounts
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccounts(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardAccountDto>>;
    public rewardAccounts(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardAccountDto>>>;
    public rewardAccounts(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardAccountDto>>>;
    public rewardAccounts(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccounts.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardAccountDto>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/accounts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get accounts filtered by status
     * @param ssu 
     * @param status 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsByStatus(ssu: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardAccountDto>>;
    public rewardAccountsByStatus(ssu: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardAccountDto>>>;
    public rewardAccountsByStatus(ssu: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardAccountDto>>>;
    public rewardAccountsByStatus(ssu: string, status: RewardAccountStatus, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsByStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling rewardAccountsByStatus.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardAccountDto>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/accounts/${encodeURIComponent(String(status))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get accounts filtered by status
     * @param ssu 
     * @param status 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsByStatusSandbox(ssu: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardAccountDto>>;
    public rewardAccountsByStatusSandbox(ssu: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardAccountDto>>>;
    public rewardAccountsByStatusSandbox(ssu: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardAccountDto>>>;
    public rewardAccountsByStatusSandbox(ssu: string, status: RewardAccountStatus, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsByStatusSandbox.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling rewardAccountsByStatusSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardAccountsByStatusSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardAccountDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/accounts/${encodeURIComponent(String(status))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ssu 
     * @param nameFilter 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsCount(ssu: string, nameFilter?: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public rewardAccountsCount(ssu: string, nameFilter?: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public rewardAccountsCount(ssu: string, nameFilter?: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public rewardAccountsCount(ssu: string, nameFilter?: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsCount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (nameFilter !== undefined && nameFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nameFilter, 'nameFilter');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/SsuRewardAccountsCount`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ssu 
     * @param sandbox 
     * @param nameFilter 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsCountSandbox(ssu: string, sandbox: string, nameFilter?: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public rewardAccountsCountSandbox(ssu: string, sandbox: string, nameFilter?: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public rewardAccountsCountSandbox(ssu: string, sandbox: string, nameFilter?: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public rewardAccountsCountSandbox(ssu: string, sandbox: string, nameFilter?: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsCountSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardAccountsCountSandbox.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (nameFilter !== undefined && nameFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nameFilter, 'nameFilter');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/SsuRewardAccountsCount`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get accounts for page
     * @param pageSize 
     * @param ssu 
     * @param page 
     * @param nameFilter 
     * @param sortColumn 
     * @param sorting 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsPage(pageSize: number, ssu: string, page: number, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardAccountDto>>;
    public rewardAccountsPage(pageSize: number, ssu: string, page: number, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardAccountDto>>>;
    public rewardAccountsPage(pageSize: number, ssu: string, page: number, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardAccountDto>>>;
    public rewardAccountsPage(pageSize: number, ssu: string, page: number, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling rewardAccountsPage.');
        }
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsPage.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling rewardAccountsPage.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (nameFilter !== undefined && nameFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nameFilter, 'nameFilter');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortColumn, 'sortColumn');
        }
        if (sorting !== undefined && sorting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sorting, 'sorting');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardAccountDto>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/pageAccounts`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get accounts for page
     * @param pageSize 
     * @param ssu 
     * @param page 
     * @param sandbox 
     * @param nameFilter 
     * @param sortColumn 
     * @param sorting 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsPageSandbox(pageSize: number, ssu: string, page: number, sandbox: string, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardAccountDto>>;
    public rewardAccountsPageSandbox(pageSize: number, ssu: string, page: number, sandbox: string, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardAccountDto>>>;
    public rewardAccountsPageSandbox(pageSize: number, ssu: string, page: number, sandbox: string, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardAccountDto>>>;
    public rewardAccountsPageSandbox(pageSize: number, ssu: string, page: number, sandbox: string, nameFilter?: string, sortColumn?: string, sorting?: SortingDirection, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling rewardAccountsPageSandbox.');
        }
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsPageSandbox.');
        }
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling rewardAccountsPageSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardAccountsPageSandbox.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (nameFilter !== undefined && nameFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nameFilter, 'nameFilter');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortColumn, 'sortColumn');
        }
        if (sorting !== undefined && sorting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sorting, 'sorting');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardAccountDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/pageAccounts`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get accounts
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardAccountsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardAccountDto>>;
    public rewardAccountsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardAccountDto>>>;
    public rewardAccountsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardAccountDto>>>;
    public rewardAccountsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardAccountsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardAccountsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardAccountDto>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/accounts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get program
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardProgramInfo(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardProgram>;
    public rewardProgramInfo(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardProgram>>;
    public rewardProgramInfo(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardProgram>>;
    public rewardProgramInfo(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardProgramInfo.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardProgram>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/program`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get program
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardProgramInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardProgram>;
    public rewardProgramInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardProgram>>;
    public rewardProgramInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardProgram>>;
    public rewardProgramInfoSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardProgramInfoSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardProgramInfoSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardProgram>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/program`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get settings
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardSettings(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardProgram>;
    public rewardSettings(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardProgram>>;
    public rewardSettings(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardProgram>>;
    public rewardSettings(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardSettings.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardProgram>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/settings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get settings
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rewardSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RewardProgram>;
    public rewardSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RewardProgram>>;
    public rewardSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RewardProgram>>;
    public rewardSettingsSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling rewardSettingsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling rewardSettingsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RewardProgram>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/settings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert rule
     * @param rewardRule 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRewardRule(rewardRule: RewardRule, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public setRewardRule(rewardRule: RewardRule, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public setRewardRule(rewardRule: RewardRule, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public setRewardRule(rewardRule: RewardRule, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rewardRule === null || rewardRule === undefined) {
            throw new Error('Required parameter rewardRule was null or undefined when calling setRewardRule.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/rule`,
            rewardRule,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert rule
     * @param sandbox 
     * @param rewardRule 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRewardRuleSandbox(sandbox: string, rewardRule: RewardRule, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public setRewardRuleSandbox(sandbox: string, rewardRule: RewardRule, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public setRewardRuleSandbox(sandbox: string, rewardRule: RewardRule, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public setRewardRuleSandbox(sandbox: string, rewardRule: RewardRule, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling setRewardRuleSandbox.');
        }
        if (rewardRule === null || rewardRule === undefined) {
            throw new Error('Required parameter rewardRule was null or undefined when calling setRewardRuleSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/rule`,
            rewardRule,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert settings
     * @param ssu 
     * @param rewardProgram 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRewardSettings(ssu: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public setRewardSettings(ssu: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public setRewardSettings(ssu: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public setRewardSettings(ssu: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling setRewardSettings.');
        }
        if (rewardProgram === null || rewardProgram === undefined) {
            throw new Error('Required parameter rewardProgram was null or undefined when calling setRewardSettings.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/settings`,
            rewardProgram,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert settings
     * @param ssu 
     * @param sandbox 
     * @param rewardProgram 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRewardSettingsSandbox(ssu: string, sandbox: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public setRewardSettingsSandbox(ssu: string, sandbox: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public setRewardSettingsSandbox(ssu: string, sandbox: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public setRewardSettingsSandbox(ssu: string, sandbox: string, rewardProgram: RewardProgram, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling setRewardSettingsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling setRewardSettingsSandbox.');
        }
        if (rewardProgram === null || rewardProgram === undefined) {
            throw new Error('Required parameter rewardProgram was null or undefined when calling setRewardSettingsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/settings`,
            rewardProgram,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets an SSUs email settings
     * @param ssu 
     * @param ssuEmailSettings 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSsuEmailSettings(ssu: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public setSsuEmailSettings(ssu: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public setSsuEmailSettings(ssu: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public setSsuEmailSettings(ssu: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling setSsuEmailSettings.');
        }
        if (ssuEmailSettings === null || ssuEmailSettings === undefined) {
            throw new Error('Required parameter ssuEmailSettings was null or undefined when calling setSsuEmailSettings.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ssu !== undefined && ssu !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ssu, 'ssu');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/ssuEmailSettings`,
            ssuEmailSettings,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets an SSUs email settings
     * @param ssu 
     * @param sandbox 
     * @param ssuEmailSettings 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSsuEmailSettingsSandbox(ssu: string, sandbox: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public setSsuEmailSettingsSandbox(ssu: string, sandbox: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public setSsuEmailSettingsSandbox(ssu: string, sandbox: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public setSsuEmailSettingsSandbox(ssu: string, sandbox: string, ssuEmailSettings: SsuEmailSettings, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling setSsuEmailSettingsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling setSsuEmailSettingsSandbox.');
        }
        if (ssuEmailSettings === null || ssuEmailSettings === undefined) {
            throw new Error('Required parameter ssuEmailSettings was null or undefined when calling setSsuEmailSettingsSandbox.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (ssu !== undefined && ssu !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ssu, 'ssu');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssuEmailSettings`,
            ssuEmailSettings,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set user permissions
     * @param sapAccountNumber 
     * @param userPermission 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserPermissions(sapAccountNumber: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public setUserPermissions(sapAccountNumber: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public setUserPermissions(sapAccountNumber: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public setUserPermissions(sapAccountNumber: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling setUserPermissions.');
        }
        if (userPermission === null || userPermission === undefined) {
            throw new Error('Required parameter userPermission was null or undefined when calling setUserPermissions.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/account/${encodeURIComponent(String(sapAccountNumber))}/userPermissions`,
            userPermission,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set user permissions
     * @param sapAccountNumber 
     * @param sandbox 
     * @param userPermission 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserPermissionsSandbox(sapAccountNumber: string, sandbox: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public setUserPermissionsSandbox(sapAccountNumber: string, sandbox: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public setUserPermissionsSandbox(sapAccountNumber: string, sandbox: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public setUserPermissionsSandbox(sapAccountNumber: string, sandbox: string, userPermission: Array<UserPermission>, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling setUserPermissionsSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling setUserPermissionsSandbox.');
        }
        if (userPermission === null || userPermission === undefined) {
            throw new Error('Required parameter userPermission was null or undefined when calling setUserPermissionsSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/account/${encodeURIComponent(String(sapAccountNumber))}/userPermissions`,
            userPermission,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tiers
     * @param ssu 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssuTiers(ssu: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardTier>>;
    public ssuTiers(ssu: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardTier>>>;
    public ssuTiers(ssu: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardTier>>>;
    public ssuTiers(ssu: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling ssuTiers.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardTier>>(`${this.configuration.basePath}/api/v1/ssu/${encodeURIComponent(String(ssu))}/tiers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tiers
     * @param ssu 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssuTiersSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RewardTier>>;
    public ssuTiersSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RewardTier>>>;
    public ssuTiersSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RewardTier>>>;
    public ssuTiersSandbox(ssu: string, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (ssu === null || ssu === undefined) {
            throw new Error('Required parameter ssu was null or undefined when calling ssuTiersSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling ssuTiersSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RewardTier>>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/ssu/${encodeURIComponent(String(ssu))}/tiers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updated a given order\&#39;s OrderProcessed flag
     * @param id 
     * @param newStatus 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderStatus(id: string, newStatus: boolean, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateOrderStatus(id: string, newStatus: boolean, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateOrderStatus(id: string, newStatus: boolean, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateOrderStatus(id: string, newStatus: boolean, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateOrderStatus.');
        }
        if (newStatus === null || newStatus === undefined) {
            throw new Error('Required parameter newStatus was null or undefined when calling updateOrderStatus.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (newStatus !== undefined && newStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>newStatus, 'newStatus');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/order/${encodeURIComponent(String(id))}/status`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updated a given order\&#39;s OrderProcessed flag
     * @param id 
     * @param newStatus 
     * @param sandbox 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderStatusSandbox(id: string, newStatus: boolean, sandbox: string, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateOrderStatusSandbox(id: string, newStatus: boolean, sandbox: string, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateOrderStatusSandbox(id: string, newStatus: boolean, sandbox: string, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateOrderStatusSandbox(id: string, newStatus: boolean, sandbox: string, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateOrderStatusSandbox.');
        }
        if (newStatus === null || newStatus === undefined) {
            throw new Error('Required parameter newStatus was null or undefined when calling updateOrderStatusSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling updateOrderStatusSandbox.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (newStatus !== undefined && newStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>newStatus, 'newStatus');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/order/${encodeURIComponent(String(id))}/status`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert product
     * @param rewardProduct 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRewardProduct(rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public updateRewardProduct(rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public updateRewardProduct(rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public updateRewardProduct(rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rewardProduct === null || rewardProduct === undefined) {
            throw new Error('Required parameter rewardProduct was null or undefined when calling updateRewardProduct.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/product`,
            rewardProduct,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert product
     * @param sandbox 
     * @param rewardProduct 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRewardProductSandbox(sandbox: string, rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReplaceOneOperationResult>;
    public updateRewardProductSandbox(sandbox: string, rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReplaceOneOperationResult>>;
    public updateRewardProductSandbox(sandbox: string, rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReplaceOneOperationResult>>;
    public updateRewardProductSandbox(sandbox: string, rewardProduct: RewardProduct, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling updateRewardProductSandbox.');
        }
        if (rewardProduct === null || rewardProduct === undefined) {
            throw new Error('Required parameter rewardProduct was null or undefined when calling updateRewardProductSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReplaceOneOperationResult>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/product`,
            rewardProduct,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert account transaction
     * @param sapAccountNumber 
     * @param adminTransactionModel 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upsertTransaction(sapAccountNumber: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public upsertTransaction(sapAccountNumber: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public upsertTransaction(sapAccountNumber: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public upsertTransaction(sapAccountNumber: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling upsertTransaction.');
        }
        if (adminTransactionModel === null || adminTransactionModel === undefined) {
            throw new Error('Required parameter adminTransactionModel was null or undefined when calling upsertTransaction.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/account/${encodeURIComponent(String(sapAccountNumber))}/transaction`,
            adminTransactionModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upsert account transaction
     * @param sapAccountNumber 
     * @param sandbox 
     * @param adminTransactionModel 
     * @param hDME2ECorrelationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upsertTransactionSandbox(sapAccountNumber: string, sandbox: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public upsertTransactionSandbox(sapAccountNumber: string, sandbox: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public upsertTransactionSandbox(sapAccountNumber: string, sandbox: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public upsertTransactionSandbox(sapAccountNumber: string, sandbox: string, adminTransactionModel: AdminTransactionModel, hDME2ECorrelationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (sapAccountNumber === null || sapAccountNumber === undefined) {
            throw new Error('Required parameter sapAccountNumber was null or undefined when calling upsertTransactionSandbox.');
        }
        if (sandbox === null || sandbox === undefined) {
            throw new Error('Required parameter sandbox was null or undefined when calling upsertTransactionSandbox.');
        }
        if (adminTransactionModel === null || adminTransactionModel === undefined) {
            throw new Error('Required parameter adminTransactionModel was null or undefined when calling upsertTransactionSandbox.');
        }

        let headers = this.defaultHeaders;
        if (hDME2ECorrelationId !== undefined && hDME2ECorrelationId !== null) {
            headers = headers.set('HDM_E2E_CorrelationId', String(hDME2ECorrelationId));
        }

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/sandbox/${encodeURIComponent(String(sandbox))}/account/${encodeURIComponent(String(sapAccountNumber))}/transaction`,
            adminTransactionModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
