import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Observable, Subscription, map, switchMap } from 'rxjs';
import localeFr from '@angular/common/locales/fr';
import localeEnAu from '@angular/common/locales/en-AU'
import localeEnNz from '@angular/common/locales/en-NZ'
import localeEnIn from '@angular/common/locales/en-IN'
import localeId from '@angular/common/locales/id'
import localeJp from '@angular/common/locales/ja'
import localeKr from '@angular/common/locales/ko'
import localeMy from '@angular/common/locales/my'
import localeEnPh from '@angular/common/locales/en-PH'
import localeEnSg from '@angular/common/locales/en-SG'
import localeTw from '@angular/common/locales/twq'
import localeTh from '@angular/common/locales/th'
import localeEsBR from '@angular/common/locales/es-BR'
import localeEnDk from '@angular/common/locales/en-DK'
import localeFi from '@angular/common/locales/fi'
import localeDe from '@angular/common/locales/de';
import localeSe from '@angular/common/locales/se'
import localeDeCh from '@angular/common/locales/de-CH'
import localeEnGB from '@angular/common/locales/en-GB';
import localeDeAT from '@angular/common/locales/de-AT';
import localeCz from '@angular/common/locales/cs';
import localeEe from '@angular/common/locales/ee';
import localeHu from '@angular/common/locales/hu';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localePL from '@angular/common/locales/pl'
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeUa from '@angular/common/locales/uk';
import localeEnZa from '@angular/common/locales/en-ZA';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEsMx from '@angular/common/locales/es-MX';
// import localeEnUs from '@angular/common/locales/en-US-POSIX';
import localeTr from '@angular/common/locales/tr';
import localeNlBe from '@angular/common/locales/nl-BE';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';

import {
  AccountGroupDto,
  CommunityUserService,
  RewardAccountDto,
} from '@neo-reward-engine-web/ecom-api';
import { EnvironmentService } from '../environment/environment.service';
import { UserService } from '@neo-reward-engine-web/auth';


registerLocaleData(localeFr, 'FR');
registerLocaleData(localeEnAu, 'AU');
registerLocaleData(localeEnNz, 'NZ');
registerLocaleData(localeEnIn, 'IN');
registerLocaleData(localeId, 'ID');
registerLocaleData(localeJp, 'JP');
registerLocaleData(localeKr, 'KR');
registerLocaleData(localeMy, 'MY');
registerLocaleData(localeEnPh, 'PH');
registerLocaleData(localeEnSg, 'SG');
registerLocaleData(localeTw, 'TW');
registerLocaleData(localeTh, 'TH');
registerLocaleData(localeEsBR, 'BR');
registerLocaleData(localeEnDk, 'DK');
registerLocaleData(localeFi, 'FI');
registerLocaleData(localeDe, 'DE');
registerLocaleData(localeSe, 'SE');
registerLocaleData(localeDeCh, 'CH');
registerLocaleData(localeEnGB, 'GB');
registerLocaleData(localeDeAT, 'AT');
registerLocaleData(localeCz, 'CZ');
registerLocaleData(localeEe, 'EE');
registerLocaleData(localeHu, 'HU');
registerLocaleData(localeLt, 'LT');
registerLocaleData(localeLv, 'LV');
registerLocaleData(localePL, 'PL');
registerLocaleData(localeRu, 'RU');
registerLocaleData(localeSk, 'SK');
registerLocaleData(localeUa, 'UA');
registerLocaleData(localeEnZa, 'za');
registerLocaleData(localeEnCa, 'CA');
registerLocaleData(localeEsMx, 'MX');
// registerLocaleData(localeEnUs, 'US');
registerLocaleData(localeTr, 'TR');
registerLocaleData(localeNlBe, 'BE');
registerLocaleData(localeIt, 'IT');
registerLocaleData(localeNl, 'NL');
registerLocaleData(localeEs, 'ES');



/**
 * multi currency service for copayment options
 * TODO: check if currencyformat is needed like this (comment Lauritz)
 */
@Injectable({
  providedIn: 'root',
})
export class MultiCurrencyService {
  private _rewardAccount$: Observable<RewardAccountDto>;
  private _accountGroup$: Observable<AccountGroupDto>;
  private _locale = '';
  private _currencyCode = '';
  private _currencyFormat = '1.2-2'; //needed?
  private subscription: Subscription = new Subscription();

  /**
   *
   * @param communityUserService injected to get countrycode of billing address
   * @param environmentService injected to retrieve sandbox to send request to
   * @param userService injected to retrieve user state
   */
  constructor(
    private readonly communityUserService: CommunityUserService,
    private readonly environmentService: EnvironmentService,
    private userService: UserService
  ) {
    this._rewardAccount$ = this.communityUserService.getUserRewardAccount(
      this.environmentService.sandBox
    );
    this._accountGroup$ = this.communityUserService.accountGroup(
      this.environmentService.sandBox
    );
  }

  get locale() {
    return this._locale;
  }
  get currencyCode() {
    return this._currencyCode;
  }
  get currencyFormat() {
    return this._currencyFormat;
  }

  retrieveMutliCurrencyParams() {
    if(this.userService.userState$.value?.userType != 'Community'){
      return;
    }

    this.subscription = this._accountGroup$.pipe(map((dto) => {
          if (dto == null) {
            return;
          }

          return dto.ssuBaseCurrency;
          // console.log(dto.billingAddress?.countryCode,dto.currency!)
          //this.subscription.unsubscribe();
        }), switchMap(currencyCode => this._rewardAccount$.pipe(map((dto) => {
      if (dto == null) {
        return;
      }
      if (dto.billingAddress?.countryCode) {
        dto.billingAddress.countryCode === 'US'
          ? (this._locale = 'en-US') :
          (this._locale = dto.billingAddress.countryCode);
      }
      this._currencyCode = currencyCode ?? dto.currency;
      // console.log(dto.billingAddress?.countryCode,dto.currency!)
      this.subscription.unsubscribe();
    })))).subscribe();


  }
}
