import { RewardTransactionViewModel } from '../reward/reward-transaction-view.model';
import { TransactionFilterModel } from './transaction-filter-model';
import { getRangeOfMonths } from '../../shared/helper-util.ts/date-helper';

/** filter transactions based on the transaction filter model
 * @param transactions
 * @param filterModel
 */
export const filterTransactions = (
  transactions: RewardTransactionViewModel[],
  filterModel?: TransactionFilterModel
): RewardTransactionViewModel[] => {
  /** false = ascending, true = descending */
  if (!filterModel) {
    return transactions;
  } else {
    switch (filterModel.property) {
      case 'points': {
        switch (filterModel.order) {
          case false: {
            return transactions.sort((a, b) => (a.points > b.points ? 1 : -1));
          }
          case true: {
            return transactions.sort((a, b) => (a.points < b.points ? 1 : -1));
          }
          default:
            return transactions;
        }
      }

      case 'timeStamp': {
        switch (filterModel.order) {
          case false: {
            return transactions.sort((a, b) =>
              a.timeStamp > b.timeStamp ? 1 : -1
            );
          }
          case true: {
            return transactions.sort((a, b) =>
              a.timeStamp < b.timeStamp ? 1 : -1
            );
          }
          default:
            return transactions;
        }
      }

      case 'expiringDate': {
        switch (filterModel.order) {
          case false: {
            return transactions.sort((a, b) =>
              a.expiringDate > b.expiringDate ? 1 : -1
            );
          }
          case true: {
            return transactions.sort((a, b) =>
              a.expiringDate < b.expiringDate ? 1 : -1
            );
          }
          default:
            return transactions;
        }
      }

      default:
        return transactions;
    }
  }
};

/** get range of next 3 months as Date Array */
export const getNextThreeMonths = (weeks: number): Date[] => {
  const today = new Date();
  const currentDate = new Date();
  const futureDate = new Date(today.setDate(today.getDate() + (weeks *7)));
  return getRangeOfMonths(currentDate, futureDate, true);
};

/** get last day of a given month
 * @param date date to get last day of month from
 */
export const getLastDayOfMonth = (date: Date): string => {
  const dateWithlastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1
  );
  return dateWithlastDayOfMonth.toISOString().substring(0, 10);
};

/** get first day of a given month
 * @param date date to get first day of month from
 */
export const getFirstDayOfMonth = (date: Date): string => {
  const dateWithlastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  );
  return dateWithlastDayOfMonth.toISOString().substring(0, 10);
};
