import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CommunityUserService, Eshop} from "@neo-reward-engine-web/ecom-api";
import {EnvironmentService} from "../../../services/environment/environment.service";
import {Observable, Subscription} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {ToastService} from "../../../services/toast/toast.service";

type RedirectType = 'CONTACT' | 'REGISTER';

@Component({
  selector: 'neo-reward-engine-web-redirect-modal',
  templateUrl: './redirect-modal.component.html',
  styleUrls: ['./redirect-modal.component.scss'],
})

export class RedirectModalComponent implements OnInit, OnDestroy {

  @Input() _redirectType!: RedirectType;
  private _eShops: any[]| null = [];
  eShop: Eshop | null = null;
  private sub: Subscription = new Subscription();

  constructor(
    private modalService: NgbActiveModal,
    private communityUserService: CommunityUserService,
    private environmentService: EnvironmentService,
    private toastService: ToastService
  ) {
    this.sub = this.communityUserService.getAllEshops(this.environmentService.sandBox).subscribe(res => {
      this._eShops = res;
      }
    );
  }

  ngOnInit(): void {
    //
  }

  onSubmit(){
    if(this.eShop){
      if(this._redirectType === 'CONTACT'){
        window.location.href = this.eShop.contactFormUrl;
      }else{
        window.location.href = this.eShop.registrationUrl;
      }
    }else{
      this.toastService.showErrorMessage('header.userInfoLogin.eShopRedirect.noSalesOrgMsg')
    }
  }

  get eShops(){
   return this._eShops;
  }

  get redirectType(){
    return this._redirectType;
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
