import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import {
  AuthConfigService,
  AuthModule,
  AuthService,
  UserService,
  AuthInterceptorService,
} from '@neo-reward-engine-web/auth';
import { Router } from '@angular/router';
import { Location, registerLocaleData } from '@angular/common';
import { CORE_COMPONENTS } from './modules/core/components/core-decl';
import { HotToastModule } from '@ngneat/hot-toast';
import { ProgressInfoColumnComponent } from './modules/core/components/mini-dashboard/progress-info-column/progress-info-column.component';
import { ProgressInfoCardComponent } from './modules/core/components/mini-dashboard/progress-info-card/progress-info-card.component';
import { MiniDashboardTextboxComponent } from './modules/core/components/mini-dashboard/mini-dashboard-textbox/mini-dashboard-textbox.component';
import { UnregisteredPointsComponent } from './modules/core/components/mini-dashboard/unregistered-points/unregistered-points.component';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import { MultilanguageService } from './modules/services/multilanguage/multilanguage.service';
import * as Sentry from '@sentry/angular-ivy';
import {
  ApiModule,
  Configuration,
  AuthService as EcomAuthService,
} from '@neo-reward-engine-web/ecom-api';
import { RedirectModalComponent } from './modules/core/components/redirect-modal/redirect-modal.component';
import {
  APP_USER_FOOTER_CONFIG_PROVIDER,
  APP_USER_SERVICE_CONFIG_PROVIDER
} from "./modules/services/multilanguage/user-service.config";

registerLocaleData(localeFr, localeDe);

const authInterceptorServiceFactory = (
  config: AuthConfigService,
  userService: UserService
) => {
  return new AuthInterceptorService(config, userService);
};

const authServiceFactory = (
  config: AuthConfigService,
  httpClient: HttpClient,
  userService: UserService,
  logger: NGXLogger,
  location: Location,
  ecomAuthService: EcomAuthService
) => {
  return new AuthService(
    config,
    httpClient,
    userService,
    logger,
    location,
    ecomAuthService
  );
};

const appInitFactory = (
  authService: AuthService,
  multilanguageService: MultilanguageService
) => {
  multilanguageService.upsertLanguage();
  return () => authService.initAuth();
};

@NgModule({
  declarations: [
    AppComponent,
    ...CORE_COMPONENTS,
    ProgressInfoColumnComponent,
    ProgressInfoCardComponent,
    MiniDashboardTextboxComponent,
    UnregisteredPointsComponent,
    RedirectModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    NgbNavModule,
    LoggerModule.forRoot({
      level: environment.enableLog ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
    }),
    AuthModule.forRoot({
      casBaseUrl: environment.casBaseUrl,
      redirectUri: environment.redirectUri,
      casClientId: environment.casClientId,
      idmLogoutUrl: environment.idmLogoutUrl,
      idmAccessTokenUrl: environment.idmAccessTokenUrl,
      idmRedirectUri: environment.idmRedirectUri,
      idmAdminRedirectUri: environment.idmAdminRedirectUri,
      secret: environment.secret,
      publicUrls: environment.publicUrls,
      backendUrl: environment.backendUrl,
      sandbox: environment.sandbox,
      rewardTokenStorageKey: environment.rewardTokenStorageKey,
      rewardUserStorageKey: environment.rewardUserStorageKey,
    }),
    HotToastModule.forRoot(),
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.backendUrl,
          withCredentials: false,
        })
    ),
  ],
  providers: [
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'fr-FR'
    // },
    {
      provide: AuthService,
      useFactory: authServiceFactory,
      deps: [
        AuthConfigService,
        HttpClient,
        UserService,
        NGXLogger,
        Location,
        EcomAuthService,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [AuthService, MultilanguageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: authInterceptorServiceFactory,
      deps: [AuthConfigService, UserService, Router],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // capture user information for prod only
      }),
    },
    // Contains the default mapping in case the user is not logged in
    // and default browser language is a single token (e.g. 'de')
    // so de is mapped to de-DE
    // provide all languages that are supported by the app and being configured in
    // the transloco config, but use only one for the mapping of a single token
    // (e.g. 'de' -> 'de-DE', but not a second one like 'de' -> 'de-AT')
    // that means Austria has to live with the default language of Germany
    // all non existing languages are going to be mapped to the default language
    APP_USER_SERVICE_CONFIG_PROVIDER([
      'de-DE',
      'en-US',
      'fr-FR',
      'pl-PL',
      'es-ES',
      'id-ID',
      'th-TH'
    ]),
    APP_USER_FOOTER_CONFIG_PROVIDER(),
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
