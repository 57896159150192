import { MenuService } from '../../../services/menu/menu.service';
import {Component, Inject} from '@angular/core';
import { FooterService } from '../../../services/footer/footer.service';
import { Observable } from 'rxjs';
import { Menu } from '../../../services/menu/menu.model';
import { AccountGroupDto } from '@neo-reward-engine-web/ecom-api';
import {RedirectModalComponent} from "../redirect-modal/redirect-modal.component";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {
  APP_USER_FOOTER_CONFIG,
  TFooterConfig
} from "../../../services/multilanguage/user-service.config";
import {TranslocoService} from "@ngneat/transloco";

/**
 * Footer Display Component for all sites
 * (e.g. includes media, ssu information, menu points)
 */
@Component({
  selector: 'neo-reward-engine-web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  private modalOptions: NgbModalOptions = { centered: true, size: 'md' };
  /**
   *
   * @param footerConfig injected to generate ssu information for footer
   * @param _footerService injected to generate ssu information for footer
   * @param _menuService injected to generate menu for in app menu points (in page routing)
   * @param modalService injected to open eShop redirect modal
   * @param translocoService injected to translate footer
   */
  constructor(
    @Inject(APP_USER_FOOTER_CONFIG) private readonly footerConfig: Map<string, TFooterConfig>,
    private _footerService: FooterService,
    private _menuService: MenuService,
    private modalService: NgbModal,
    private readonly translocoService: TranslocoService
  ) {
    this._footerService.retrieveSsuInfo();
  }

  /** information of the ssu of the logged in user */
  get footerInformation$(): Observable<AccountGroupDto | null> {
    return this._footerService.ssuInfo$;
  }
  /** to generate in app menu points in footer (e.g. overview page only if user can't redeem) */
  get menuInformation$(): Observable<Menu> {
    return this._menuService.menuConfig$;
  }
  /** to see if user has permission to redeem */
  get canRedeem$() {
    return this._menuService.canRedeem$;
  }
  /** to see if user is registered */
  get isLoggedInAndRegistered(): boolean {
    return this._menuService.isLoggedIn && this._menuService.isRegistered;
  }
  /** year to display in copyright of footer */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  openRedirectModal(){
    const modalRef = this.modalService.open(RedirectModalComponent,this.modalOptions);
    modalRef.componentInstance._redirectType = 'CONTACT';
  }

  /** takes ssuinfo and returns only language part of locale */
  getCurrentLanguage(ssuInfo: AccountGroupDto): string {
    return ssuInfo.defaultLocale?.split("_")[0] || "en";
  }

  get footerConfig4ActiveLang(): TFooterConfig | undefined {
    const activeLang = this.translocoService.getActiveLang();
    if (this.footerConfig.has(activeLang)) {
      return this.footerConfig.get(activeLang);
    }
    return undefined;
  }
}
